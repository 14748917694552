import React from "react"
import { graphql } from "gatsby"
import { Box, Container, Grid, Heading } from "@theme-ui/components"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import { getPagePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import TitleAndBody from "../components/blocks/titleAndBody"
import PageCarousel from "../components/blocks/pageCarousel"
import Image from "../components/blocks/image"
import ImageGallery from "../components/blocks/imageGallery"
import OrderedList from "../components/blocks/orderedList"
import ItemCarousel from "../components/blocks/itemCarousel"
import Accordion from "../components/blocks/accordion"
import DocumentCollection from "../components/blocks/documentCollection"
import Embed from "../components/blocks/embed"
import PageHero from "./pageHero"
import ImageAndText from "../components/blocks/imageAndText"
import NumbersCollection from "../components/blocks/numbersCollections"
import ContactForm from "../components/blocks/contactForm"
import FooterServiceInformation from "../components/blocks/footerServiceInformation"
import PageReferenceThumb from "../components/blocks/pageReferenceThumb"
import SimpleImageAndText from "../components/blocks/simpleImageAndText"
import TextBlock from "../components/blocks/textBlock"
import IconBanner from "../components/blocks/iconBanner"
import IconsAndText from "../components/blocks/iconsAndText"
import StaffCarousel from "../components/blocks/staffCarousel"
import WorkWithUsBanner from "../components/blocks/workWithUsBanner"
import ApplicationForm from "../components/blocks/applicationForm"
import { useFavicon } from "../hooks/useFavicon"
import PageSubtitle from "../components/pageSubtitle"
import HtmlParser from "../components/htmlParser"
import FilterMetaTagDescription from "../utils/filterMetaTagDescription"
import JobsCollection from "../components/blocks/jobsCollection"
import SimpleImageTextCollection from "../components/blocks/simpleImageTextCollection"
import SocafProducts from "../components/blocks/socafProducts"
import ProductFeatures from "../components/blocks/productFeatures"

const LocationsMap = loadable(
  () => import("../components/blocks/locationMap"),
  { ssr: false }
)

const Page = ({
  data: {
    page,
    site,
    footerServiceInformation,
    socafProducts,
    categories,
    familyCategories,
  },
}) => {
  console.log(page.id)

  const favicon = useFavicon().site.faviconMetaTags

  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })
  console.log(page)
  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms
        seo={FilterMetaTagDescription(page.seoMetaTags)}
        favicon={favicon}
      >
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} image={page.heroImage} />
      <PageSubtitle subtitle={page.subtitle} />
      <Box>
        {page.content.map(block => (
          <Box as="section" key={block.id}>
            {block.model.apiKey === "jobs_collection" && (
              <JobsCollection title={block.title} items={block.jobs} />
            )}
            {block.model.apiKey === "simple_image_text_collection" && (
              <SimpleImageTextCollection items={block.items} />
            )}
            {block.model.apiKey === "icon_banner" && (
              <IconBanner background={block.background} icons={block.icons} />
            )}
            {block.model.apiKey === "icon_and_text" && (
              <IconsAndText
                kicker={block.kicker}
                title={block.title}
                description={block.description}
                icons={block.icons}
              />
            )}
            {block.model.apiKey === "title_and_body" && (
              <Container>
                <TitleAndBody
                  title={block.content.title}
                  body={block.content.body}
                />
              </Container>
            )}
            {block.model.apiKey === "simple_image_and_text" && (
              <Box
                sx={{
                  position: "relative",
                  "&:before": {
                    content: "''",
                    position: "absolute",
                    width: "40%",
                    height: "300px",
                    bottom: 0,
                    right: 0,
                    backgroundColor: "Grey",
                  },
                }}
              >
                <Container sx={{ my: [0] }}>
                  <SimpleImageAndText
                    description={block.description}
                    title={block.title}
                    image={block.image}
                  />
                </Container>
              </Box>
            )}
            {block.model.apiKey === "reference_section" && (
              <Box
                sx={{
                  position: "relative",
                  "&:before": {
                    content: "''",
                    position: "absolute",
                    width: "40%",
                    height: "300px",
                    top: 0,
                    right: 0,
                    backgroundColor: "Grey",
                  },
                }}
              >
                <Container>
                  {block.reference.map((ref, index) => (
                    <PageReferenceThumb reference={ref} index={index} />
                  ))}
                </Container>
              </Box>
            )}
            {block.model.apiKey === "text_block" && (
              <Box
                sx={{
                  backgroundColor: block.darkBackground ? "Grey" : "none",
                }}
              >
                <Container sx={{ pb: [0, 0, 0, 0] }}>
                  <TextBlock
                    title={block.title}
                    body={block.body}
                    alignLeftOrTop={block.alignLeftOrTop}
                  />
                </Container>
              </Box>
            )}
            {block.model.apiKey === "ordered_list" && (
              <OrderedList
                title={block.title}
                subtitle={block.subtitle}
                body={block.body}
              />
            )}
            {block.model.apiKey === "page_carousel" && (
              <PageCarousel title={block.title} pages={block.pages} />
            )}
            {block.model.apiKey === "item_carousel" && (
              <ItemCarousel items={block.items} />
            )}
            {block.model.apiKey === "accordion" && (
              <Accordion title={block.title} items={block.items} />
            )}
            {block.model.apiKey === "document_collection" && (
              <DocumentCollection
                title={block.title}
                documents={block.documents}
                showPublicationDate={block.showPublicationDate}
              />
            )}
            {block.model.apiKey === "image" && <Image image={block.image} />}
            {block.model.apiKey === "locations_map" && (
              <>
                <Container
                  variant="lg"
                  sx={{
                    p: [0, 0, 0, 0],
                    pb: [0, 0, 0, 0],
                    mt: ["-50px", "-130px"],
                    position: "relative",
                    backgroundColor: "light",
                  }}
                >
                  <LocationsMap locations={block.locations} />
                </Container>
                <Container>
                  <Grid columns={[1, 3]} gap={[1, 3]} sx={{ mt: [5] }}>
                    {block.locations.map((city, index) => (
                      <Box
                        sx={{
                          backgroundColor:
                            index === 0
                              ? "primary"
                              : index === 1
                              ? "dark"
                              : "secondary",
                          p: [4],
                          pt: [5, 10],
                        }}
                      >
                        <Box>
                          <Heading
                            as="div"
                            variant="sectionTitle"
                            sx={{
                              color: index === 0 ? "light" : "light",
                              "h2::after": {
                                backgroundColor: "light",
                              },
                            }}
                          >
                            <Box as="h2">{city.name}</Box>
                          </Heading>
                        </Box>
                        <HtmlParser html={city.body} theme="dark" />
                      </Box>
                    ))}
                  </Grid>
                </Container>
              </>
            )}
            {(block.model.apiKey === "socaf_range" ||
              block.model.apiKey === "product_collection") && (
              <SocafProducts
                block={block}
                products={
                  block.model.apiKey === "socaf_range"
                    ? socafProducts.nodes
                    : block.products
                }
                categories={categories}
                familyCategories={familyCategories}
              />
            )}
            {block.model.apiKey === "socaf_feature" && (
              <ProductFeatures block={block} />
            )}
            {block.model.apiKey === "embed" && (
              <Embed
                title={block.title}
                code={block.code}
                fullWidth={block.fullWidth}
              />
            )}
            {block.model.apiKey === "image_gallery" && (
              <Container>
                <ImageGallery images={block.images} />
              </Container>
            )}
            {block.model.apiKey === "numbers_collection" && (
              <NumbersCollection
                title={block.title}
                image={block.image}
                numbers={block.numbers}
              />
            )}
            {block.model.apiKey === "contact_form" && (
              <Container>
                <ContactForm
                  contactPage={page.slug === "contatti"}
                  kicker={block.kicker}
                  title={block.title}
                  subtitle={block.subtitle}
                  privacyPolicyDescription={block.privacyPolicyDescription}
                  newsletterDescription={block.newsletterDescription}
                />
              </Container>
            )}
            {block.model.apiKey === "image_and_text" && (
              <ImageAndText
                label={block.content.label}
                subtitle={block.content.subtitle}
                title={block.content.title}
                body={block.content.body}
                image={block.image}
                rightAligned={block.rightAligned}
                link={block.content.link}
                backgroundColor={block.backgroundColor}
              />
            )}
            {block.model.apiKey === "staff_carousel" && (
              <StaffCarousel kicker={block.kicker} staff={block.staff} />
            )}
            {block.model.apiKey === "work_with_us_banner" && (
              <WorkWithUsBanner
                title={block.title}
                description={block.description}
                link={block.page}
              />
            )}
            {block.model.apiKey === "application_form" && (
              <ApplicationForm
                kicker={block.kicker}
                title={block.title}
                summary={block.summary}
                subtitle={block.subtitle}
                privacyPolicyDescription={block.privacyPolicyDescription}
                newsletterDescription={block.newsletterDescription}
              />
            )}
            {block.model.apiKey === "generic_text" && (
              <Box>
                <Container>
                  <Box dangerouslySetInnerHTML={{ __html: block.text }} />
                </Container>
              </Box>
            )}
          </Box>
        ))}
      </Box>
      {/* <Box>
        {page.body && (
          <Container>
            <RichContentStructuredText text={page.body} />
          </Container>
        )}
      </Box> */}
      {page.showServices && (
        <FooterServiceInformation services={footerServiceInformation.service} />
      )}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }

    socafProducts: allDatoCmsProduct(
      filter: { socafGear: { eq: true } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        socafGear
        title
        slug
        description
        id
        title
        shortTitle
        shortDescription
        locale
        slug
        availability
        thumbnailImage {
          gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
        }
        fullWidthThumbnail
        images {
          gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
        }
      }
    }
    categories: allDatoCmsProductCategory(
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        title
        locale
        ...ProductCategoryPageDetails
        model {
          apiKey
        }
      }
    }
    familyCategories: allDatoCmsProductFamily(
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        title
        shortTitle
        locale
        slug
        categories {
          slug
          id
          title
          products {
            id
            title
            slug
          }
        }
        model {
          apiKey
        }
      }
    }
    page: datoCmsPage(id: { eq: $id }) {
      ...PageDetails
      ...PageTreeParent
      ...AllSlugLocales
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsProductCollection {
          id
          title
          products {
            socafGear
            title
            slug
            description
            id
            title
            shortTitle
            shortDescription
            locale
            slug
            availability
            thumbnailImage {
              gatsbyImageData(
                width: 1920
                placeholder: NONE
                forceBlurhash: false
              )
            }
            fullWidthThumbnail
            images {
              gatsbyImageData(
                width: 1920
                placeholder: NONE
                forceBlurhash: false
              )
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsSocafFeature {
          id
          model {
            apiKey
          }
          features {
            subtitle
            title
          }
        }

        ... on DatoCmsSocafRange {
          id
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsSimpleImageTextCollection {
          id
          items {
            id
            title
            description
            image {
              gatsbyImageData(
                width: 720
                placeholder: BLURRED
                forceBlurhash: false
              )
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsJobsCollection {
          id
          title
          jobs {
            id
            title
            description
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsIconBanner {
          id
          background {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: {
                blendColor: "#212C30"
                blendMode: "multiply"
                blendAlpha: 60
              }
            )
          }
          icons {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }

        ... on DatoCmsIconAndText {
          id
          kicker
          title
          description
          icons {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }

        ... on DatoCmsTextBlock {
          id
          title
          darkBackground
          body {
            value
          }
          alignLeftOrTop
          model {
            apiKey
          }
        }
        ... on DatoCmsGenericText {
          id
          text
          model {
            apiKey
          }
        }
        ... on DatoCmsSimpleImageAndText {
          id
          title
          description
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsStaffCarousel {
          id
          kicker
          staff {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsWorkWithUsBanner {
          id
          title
          description
          page {
            ... on DatoCmsInternalLink {
              id: originalId
              anchor
              locale
              model {
                apiKey
              }
              link {
                ... on DatoCmsNewsPage {
                  ...NewsDetails
                }
                ... on DatoCmsReferencesPage {
                  ...ReferencesDetails
                }
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
                ... on DatoCmsArticle {
                  ...ArticleDetails
                  ...ArticleAllSlugLocales
                }
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsReferenceSection {
          id
          model {
            apiKey
          }
          reference {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
        }
        ... on DatoCmsNumbersCollection {
          id
          title
          image {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: {
                blendColor: "#212C30"
                blendMode: "multiply"
                blendAlpha: 60
              }
            )
          }
          numbers {
            legend
            float
            suffix
            prefix
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsApplicationForm {
          id
          kicker
          title
          subtitle
          summary
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
        ... on DatoCmsEmbed {
          id
          ...EmbedDetails
        }
        ... on DatoCmsImageAndText {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          rightAligned
          backgroundColor {
            hex
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImage {
          id
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsCategory {
          id
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsLocationsMap {
          id
          locations {
            originalId
            name
            body
            coordinates {
              latitude
              longitude
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageGallery {
          id
          ...ImageGallery
        }
        ... on DatoCmsDocumentCollection {
          id
          title
          showPublicationDate
          documents {
            title
            subtitle
            documents {
              url
              title
              format
            }
            meta {
              firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsAccordion {
          id
          title
          items: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndBody {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsOrderedList {
          id
          title
          subtitle
          body {
            blocks
            links
            value
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPageCarousel {
          id
          title
          pages {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
      }
    }

    footerServiceInformation: datoCmsServiceInformation {
      id
      service {
        ... on DatoCmsRichContent {
          ...RichContent
        }
      }
    }
  }

  fragment RichContent on DatoCmsRichContent {
    title
    label
    subtitle
    image {
      url
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    body {
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
          link {
            ... on DatoCmsNewsPage {
              ...NewsDetails
            }
            ... on DatoCmsReferencesPage {
              ...ReferencesDetails
            }
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
            ... on DatoCmsArticle {
              ...ArticleDetails
              ...ArticleAllSlugLocales
            }
          }
        }
      }
      value
    }
    link {
      __typename
      ... on DatoCmsInternalLink {
        id: originalId
        anchor
        locale
        model {
          apiKey
        }
        link {
          ... on DatoCmsNewsPage {
            ...NewsDetails
          }
          ... on DatoCmsReferencesPage {
            ...ReferencesDetails
          }
          ... on DatoCmsReference {
            ...ReferenceDetails
            ...ReferenceAllSlugLocales
          }
          ... on DatoCmsPage {
            ...PageDetails
            ...PageTreeParent
            ...AllSlugLocales
          }
          ... on DatoCmsArticle {
            ...ArticleDetails
            ...ArticleAllSlugLocales
          }
        }
      }
    }
    model {
      apiKey
    }
  }

  fragment AllSlugLocales on DatoCmsPage {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment PageDetails on DatoCmsPage {
    id
    locale
    title
    subtitle
    slug
    root
    body {
      value
    }
    showServices
    model {
      apiKey
    }
    heroImage {
      gatsbyImageData(
        width: 1920
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 60
        }
      )
      mobile: gatsbyImageData(
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          fit: "crop"
          ar: "1 : 1"
          h: "400"
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 30
        }
      )
    }
  }

  fragment EmbedDetails on DatoCmsEmbed {
    title
    code
    fullWidth
    model {
      apiKey
    }
  }

  fragment PageTreeParent on DatoCmsPage {
    treeParent {
      id
      title
      slug
      root
      locale
      ...AllSlugLocales
      treeParent {
        id
        title
        slug
        root
        locale
        ...AllSlugLocales
      }
    }
  }
`
